import React from 'react';

export type EventKey = string | number;

export interface SelectableContextProps {
    children: React.ReactNode | React.ReactNode[];
    eventKey: EventKey;
}

export interface SelectableContextValues {
    eventKey: EventKey;
}

export const SelectableContext = React.createContext({} as SelectableContextValues);

export const SelectableProvider = (props: SelectableContextProps): React.JSX.Element => {
    const {children, eventKey} = props;

    const contextValue = React.useMemo(
        () => ({
            eventKey
        }),
        [eventKey]
    );

    return <SelectableContext.Provider value={contextValue}>{children}</SelectableContext.Provider>;
};

export default SelectableContext;
