import React from 'react';
import Icon, {IconProps} from '../icon';

export const SelectableIcon: React.FC<IconProps> = (props: IconProps) => <Icon width="37.249" height="37.255"
                                                                               viewBox="0 0 37.249 37.255" {...props}>
    <g id="Group_43" data-name="Group 43" transform="translate(-85.375 -921.372)">
        <circle
            id="Ellipse_70"
            data-name="Ellipse 70"
            cx="15"
            cy="15"
            r="15"
            transform="translate(89 925)"
            fill="#cc3e0c"
        />
        <path
            id="np_checkmark_2573373_000000"
            d="M34.739,34.742a18.625,18.625,0,1,0-26.334,0A18.627,18.627,0,0,0,34.739,34.742ZM12.6,21.959a1.172,1.172,0,0,1,1.653,0l3.8,3.784L28.895,14.916a1.185,1.185,0,0,1,1.653.011,1.159,1.159,0,0,1,0,1.642L18.859,28.21a1.115,1.115,0,0,1-.815.337,1.148,1.148,0,0,1-.827-.337l-4.622-4.6a1.174,1.174,0,0,1,0-1.653Z"
            transform="translate(82.428 918.433)"
            fill="#fff"
        />
    </g>
</Icon>;

export default SelectableIcon;
