import lightTheme from "@crm/theme/light-theme";
import {Arrow} from "@crm/utils/images";
import React from "react"
import styled from "styled-components"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode[] | React.ReactNode;
    opacity?: number;
    color?: string;
}

const NextButtonMobileRoot = styled.button<{ opacity?: number }>`
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    width: 100%;
    margin-top: 10px;
    padding: 13px 17px;
    background-color: ${({theme}) => theme.palette.common.turquoise}; //${({theme}) => theme.palette.common.darkBlue};
    font-family: ${({theme}) => theme.typography.primary.fontFamily};
    font-size: 22px;
    text-align: left;
    border-radius: 10px;
    box-shadow: 0 0 15px #0000004d;
    opacity: ${props => props.opacity ?? 1};

    ${({theme}) => theme.breakpoints.down('mobileM')} {
        font-size: 19px;
    }
`

const ArrowMobile = styled(Arrow)<{ color: string }>`
    width: 25px;

    path {
        fill: ${props => props.color};
    }
`

const LongMobileButton = (props: Props) => {
    const {children, opacity, color = lightTheme.palette.common.darkBlue, ...rest} = props;
    return <NextButtonMobileRoot opacity={opacity} {...rest} >
        <div className="flex justify-between items-center" style={{color: color}}>
            {children}
            <ArrowMobile color={color}/>
        </div>
    </NextButtonMobileRoot>
}

export default LongMobileButton