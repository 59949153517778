import {EventKey} from '@crm/contexts/selectable-context/selectable-context';
import useSelectable from '@crm/hooks/use-selectable';
import useSelectableGroup from '@crm/hooks/use-selectable-group';
import React, {useCallback} from 'react';
import styled from 'styled-components';
import Fade from '../fade';
import {SelectableItemOverlay} from './selectable-item-overlay';

interface Props {
    children: React.ReactNode;
}

interface OverlayProps {
    $borderRadius?: string;
}

const Overlay = styled.div<OverlayProps>`
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
    border-radius: ${({$borderRadius}) => $borderRadius ? $borderRadius : 'inherit'};
    border: 3px solid white;
`;

export const SelectableItem: React.FC<Props> = props => {
    const {children} = props;
    const {selected, multiSelectable} = useSelectableGroup();
    const {eventKey} = useSelectable();
    const [childBorderRadius, setChildBorderRadius] = React.useState<string | undefined>(undefined);

    if (!React.isValidElement(children)) {
        throw Error('Child to SelectableItem is not a valid element');
    }

    const visible = selected && multiSelectable
        ? (selected as EventKey[]).includes(eventKey)
        : selected as EventKey === eventKey;

    const targetRef = useCallback((node: HTMLElement) => {
        if (node !== null) {
            const initialBorderRadius = window.getComputedStyle(node).borderRadius;
            setChildBorderRadius(initialBorderRadius);
        }
    }, []);

    if (visible) {
        return React.cloneElement(
            children,
            {
                // style: { border: '1px solid red', boxSizing: 'content-box', position: 'relative' },
                style: {position: 'relative'},
                ref: targetRef,
                ...children.props
            },
            children.props.children,
            <Fade in={true} unmountOnExit>
                <Overlay $borderRadius={childBorderRadius}>
                    <SelectableItemOverlay/>
                </Overlay>
            </Fade>
        );
    }

    return <>{children}</>;
};

export default SelectableItem;
