import SelectableContext, {SelectableContextValues} from '@crm/contexts/selectable-context/selectable-context';
import React from 'react';

export const useSelectable = (): SelectableContextValues => {
    const values = React.useContext(SelectableContext);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!values) {
        throw new Error(
            'SelectableContext cannot be rendered outside the SelectableProvider component'
        );
    }
    return {...values};
};

export default useSelectable;
