import React from 'react';
import IconButton from '@crm/components/icon-button';
import RoundArrowIcon from '@crm/components/icon/round-arrow-icon';
import styled, {css} from 'styled-components';
import LongMobileButton from "@crm/components/button/long-mobile-button";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    alignment?: 'left' | 'right' | 'center';
    opacity?: number
    mobile?: boolean
    color?: string
    style?: React.CSSProperties
}

interface NextButtonRootProps {
    alignment?: 'left' | 'right' | 'center';
    opacity?: number
}

const leftStyle = css`
  margin-right: auto;
`;

const rightStyle = css`
  margin-left: auto;
`;

const centerStyle = css`
  margin-right: auto;
  margin-left: auto;
`;

const alignmentMapper = {
    left: leftStyle,
    right: rightStyle,
    center: centerStyle
};

const NextButtonRoot = styled(IconButton)<NextButtonRootProps>`
  margin-top: auto;
  ${({alignment}) => alignment && alignmentMapper[alignment]};
  opacity: ${props => props.opacity ?? 1};

  &:hover:not([disabled]) {
    path {
      transition: 0.25s;
      fill: ${props => props.opacity == 1 || props.opacity == undefined ? `white` : ''};
    }
  }

  &:hover:disabled {
    cursor: default;
  }

  path {
    transition: 0.25s;
  }
`;

const NextButton: React.FC<Props> = (props: Props) => {
    const {alignment, ...rest} = props;
    if (!props.mobile) {
        return <NextButtonRoot style={props.style} opacity={props.opacity} alignment={alignment}
                               data-cy={'next-button'} {...rest}>
            <RoundArrowIcon/>
        </NextButtonRoot>;
    } else {
        return <LongMobileButton style={props.style} opacity={props.opacity} color={props.color}
                                 data-cy={'next-button'} {...rest}>
            Næste
        </LongMobileButton>
    }
};

export default NextButton;
