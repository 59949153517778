import React from 'react';
import styled from "styled-components";

interface Props {
    symbol: string;
}

const Square = styled.span`
  line-height: 1;
  vertical-align: super;
  font-size: 80%;
`

const Squared = (props: Props) => {
    const {symbol} = props
    return <>
        {symbol}<Square>2</Square>
    </>;
};

export default Squared;