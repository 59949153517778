import {EventKey} from '@crm/contexts/selectable-context/selectable-context';
import SelectableGroupContext, {
    SelectableGroupContextValues
} from '@crm/contexts/selectable-group-context/selectable-group-context';
import React from 'react';

export const useSelectableGroup = (): SelectableGroupContextValues<EventKey> => {
    const values = React.useContext(SelectableGroupContext);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!values) {
        throw new Error(
            'SelectableGroupContext cannot be rendered outside the SelectableGroupProvider component'
        );
    }
    return {...values};
};

export default useSelectableGroup;
