import {EventKey} from '@crm/contexts/selectable-context/selectable-context';
import {SelectableGroupProvider} from '@crm/contexts/selectable-group-context';
import React from 'react';

interface Props<T extends EventKey> {
    children: React.ReactNode | React.ReactNode[];
    multiSelectable?: boolean;
    selected: T | T[] | undefined;
}

export const SelectableGroup: React.FC<Props<EventKey>> = props => {
    const {children, selected, multiSelectable = false} = props;

    return <SelectableGroupProvider selected={selected} multiSelectable={multiSelectable}>
        {children}
    </SelectableGroupProvider>;
};

export default SelectableGroup;
