import React from 'react';
import styled from 'styled-components';
import SelectableIcon from '../icon/selectable-icon';

const SelectableItemOverlayRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SelectableItemOverlay: React.FC = () =>
    <SelectableItemOverlayRoot>
        <SelectableIcon/>
    </SelectableItemOverlayRoot>;
