import LoadingIndicator from '@crm/components/icon/loading-indicator';
import Selectable from '@crm/components/selectable';
import SelectableGroup from '@crm/components/selectable-group';
import SelectableItem from '@crm/components/selectable-item';
import Typography from '@crm/components/typography';
import {EventKey} from '@crm/contexts/selectable-context/selectable-context';
import Image from 'next/legacy/image';
import React, {useEffect} from 'react';
import styled, {useTheme} from 'styled-components';
import NextButton from '../../buttons/next-button';
import lightTheme from '@crm/theme/light-theme';
import {routes} from '@crm/routes';
import {Divider} from '@crm/components/divider';
import AddressAndArea
    from '@crm/components/layout/landing-page/order-wizard/steps/choose-service-type/address-and-area';
import useMediaQuery from '@crm/hooks/use-media-query/use-media-query';
import PreviousButton from '../../buttons/previous-button';
import {housekeeprApi} from "@crm/api/housekeepr-api";
import {wizardActions, wizardSelectors} from "@crm/services/wizard-slice";
import {skipToken} from "@reduxjs/toolkit/query";
import {useAppDispatch, useAppSelector} from "@crm/services/store";
import {useRouter} from "next/router";
import {windowCleaningActions} from "@crm/services/window-cleaning-slice";
import {cleaningActions} from "@crm/services/cleaning-slice";

interface Props {
    pageFlow?: boolean;

    onDidMount?(): any;
}

const ChooseServiceRoot = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    justify-content: space-between;
`;

const ImageContainer = styled.div`
    width: clamp(110px, 16vw, 125px);

    ${({theme}) => theme.breakpoints.down('mobile')} {
        width: 120px;
    }

    max-width: 140px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    aspect-ratio: 1 / 1;
`;

const ServiceContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    margin-bottom: 24px;
`;

const ServiceTypesHeader = styled(Typography)`
    margin-top: 15px;
    margin-bottom: 14px;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        margin-top: 15px;
        margin-bottom: 20px;
    }
`;

const ServiceTypeLabel = styled(Typography)`
    margin-left: 5%;
    user-select: none;
    font-size: large;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-weight: lighter;
        font-size: 18px;
    }
`;

const ServiceTypeContainer = styled.div<{
    $pageFlow?: boolean
}>`
    ${props => !props.$pageFlow && `max-height: 40vh`};
    overflow-y: auto;

    ${({theme}) => theme.breakpoints.down('mobile')} {
    }
`;

const ChooseServiceType = React.forwardRef<HTMLDivElement, Props>(function ChooseService(
    props,
    ref
) {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const [attemptedNext, setAttemptedNext] = React.useState(false);

    const dawaAddress = useAppSelector(wizardSelectors.selectAddress);
    const area = useAppSelector(wizardSelectors.selectArea);
    const serviceTypeId = useAppSelector(wizardSelectors.selectServiceTypeId);

    const {data: address} = housekeeprApi.useRegisterAddressQuery(dawaAddress
        ? {
            darId: dawaAddress.adresse.id,
            area: area
        }
        : skipToken
    );
    const {data: serviceTypes} = housekeeprApi.useGetServiceTypesQuery();
    const serviceType = serviceTypes?.find(s => s.id === serviceTypeId);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('mobile'));

    useEffect(() => props.onDidMount?.(), [props]);

    const handleAreaChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const value = parseInt(event.target.value.replace(/\D/g, ''));
            if (isNaN(value)) {
                dispatch(wizardActions.setArea(0));
            } else {
                dispatch(wizardActions.setArea(value));
            }
        } catch (e) {
            //
        }
    }, [dispatch]);

    const onServiceClick = (eventKey: EventKey) => {
        const clickedServiceType = serviceTypes?.find(serviceType => serviceType.id == eventKey);
        if (!clickedServiceType) {
            return;
        }
        dispatch(wizardActions.setServiceType(clickedServiceType));
    }

    /**
     * Handle the event triggered by clicking next step differently depending on the flow.
     */
    const handleOnNextClick = React.useCallback(async () => {
        if (dawaAddress == undefined) {
            return;
        }

        if (!serviceType) {
            setAttemptedNext(true);
            return;
        }

        switch (serviceType.type) {
            case 'cleaning':
                dispatch(cleaningActions.startFromWizard({
                    serviceTypeId: serviceType.id,
                    address: dawaAddress,
                    area: area,
                }));

                router.push(routes.orderCleaning.href).catch(console.error);
                break;
            case 'window_cleaning':
                dispatch(windowCleaningActions.resetState());
                if (address && dawaAddress) {
                    dispatch(windowCleaningActions.setAddress({
                        tekst: address.address_string,
                        adresse: dawaAddress.adresse,
                    }));
                }
                router.push(routes.orderWindowCleaning.href).catch(console.error);
                break;
        }
    }, [serviceType, address, area, dawaAddress, router, dispatch]);

    return <ChooseServiceRoot ref={ref}>
        <div>
            <AddressAndArea
                areaString={area?.toString() ?? address?.area.toString()}
                address={address?.address_string}
                onChange={handleAreaChange}
            />

            <Divider/>

            <ServiceTypesHeader variant="h40">Vælg servicetype</ServiceTypesHeader>

            {serviceTypes === undefined
                ? <LoadingIndicator/>
                : <ServiceTypeContainer $pageFlow={props.pageFlow}>
                    <SelectableGroup selected={serviceType?.id}>
                        {serviceTypes
                            .filter(s => s.website_available === true)
                            .map(serviceType =>
                                <Selectable
                                    onClick={onServiceClick}
                                    eventKey={serviceType.id}
                                    key={serviceType.id}
                                >
                                    <ServiceContainer data-cy={`servicetype-${serviceType.id}`}>
                                        <SelectableItem>
                                            <ImageContainer>
                                                {serviceType.logo && <Image
                                                    draggable={false}
                                                    className="object-cover"
                                                    width={140}
                                                    height={140}
                                                    alt="Sådan kommer du i gang - bestil"
                                                    src={serviceType.logo}
                                                />}
                                            </ImageContainer>
                                        </SelectableItem>
                                        <ServiceTypeLabel>{serviceType.name}</ServiceTypeLabel>
                                    </ServiceContainer>
                                </Selectable>
                            )
                        }
                    </SelectableGroup>
                </ServiceTypeContainer>
            }
            {attemptedNext && !serviceType &&
                <Typography styles={{root: 'margin-top: auto;'}} variant="error">
                    Vælg en servicetype for at gå videre
                </Typography>
            }
        </div>
        <div className="flex justify-center gap-5 mb-28 mobile:mb-0">
            <PreviousButton
                onClick={mobile
                    ? () => {
                        dispatch(wizardActions.resetState());
                    }
                    : undefined
                }
                color={lightTheme.palette.common.lightTurquoise}
                disabled={!mobile}
                style={{opacity: mobile ? '1' : '0.5'}}
            />
            <NextButton
                opacity={serviceType ? 1 : 0.5}
                onClick={handleOnNextClick}
                color={lightTheme.palette.common.lightTurquoise}
            />
        </div>
    </ChooseServiceRoot>;
});

export default ChooseServiceType;
