import styled from 'styled-components';
import RoundArrowIcon from '@crm/components/icon/round-arrow-icon';
import React from 'react';
import NextButton from '../next-button';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    alignment?: 'left' | 'right' | 'center';
}

const PreviousButtonRoot = styled(NextButton)`
  transform: rotate(180deg);
`;

export const PreviousButton: React.FC<Props> = (props: Props) =>
    <PreviousButtonRoot {...props} data-cy={'previous-button'}>
        <RoundArrowIcon/>
    </PreviousButtonRoot>;

export default PreviousButton;
