import React from 'react';
import styled from 'styled-components';

const DividerRoot = styled.hr`
    margin: 0;
    flex-shrink: 0;
    border-width: 0 0 thin;
    border-style: solid;
    border-color: ${({theme}) => theme.palette.common.darkBlue};
`;

export const Divider = (props: { style?: React.CSSProperties }) => <DividerRoot style={props.style}/>;

export default Divider;
