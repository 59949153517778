import React from 'react';
import Icon, {IconProps} from '../icon';

export const RoundArrowIcon: React.FC<IconProps> = (props: IconProps) => <Icon width="45" height="45"
                                                                               viewBox="0 0 45 45" {...props}>
    <path
        id="Path_43"
        data-name="Path 43"
        d="M48.129,36.849a1.592,1.592,0,0,0-2.329,2.17l4.644,4.988H35.572a1.588,1.588,0,0,0,0,3.176H50.444L45.8,52.171a1.6,1.6,0,0,0,.079,2.249,1.54,1.54,0,0,0,1.085.423,1.616,1.616,0,0,0,1.164-.5l7.132-7.661a1.593,1.593,0,0,0,0-2.17Z"
        transform="translate(-22.334 -23.094)"
        fill="#2a4d4f"
    />
    <path
        id="Path_44"
        data-name="Path 44"
        d="M38.906,16.406a22.5,22.5,0,1,0,22.5,22.5,22.532,22.532,0,0,0-22.5-22.5Zm0,41.86a19.36,19.36,0,1,1,19.36-19.36A19.383,19.383,0,0,1,38.906,58.266Z"
        transform="translate(-16.406 -16.406)"
        fill="#2a4d4f"
    />
</Icon>;

export default RoundArrowIcon;
