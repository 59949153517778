import React from 'react';
import Typography from "@crm/components/typography";
import TextField from "@crm/components/text-field";
import styled from "styled-components";
import Squared from "@crm/components/squared/squared";

interface Props {
    address?: string | undefined;
    areaString?: string;
    readonly?: boolean;

    onChange?(event: React.ChangeEvent<HTMLInputElement>): any
}

const AddressInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        display: initial;
    }
`;

const AddressContainer = styled.div`
    flex: calc(4 / 5);

    ${({theme}) => theme.breakpoints.down('mobile')} {
        width: 100%;
        margin-bottom: 10px;
    }

    & > div {
        ${({theme}) => theme.breakpoints.up("mobile")} {
            display: flex;
            align-items: center;
            height: 55px;
        }

        & > p {
            ${({theme}) => theme.breakpoints.down('mobile')} {
                margin-top: 5px;
            }
        }
    }
`;

const AreaContainer = styled.div`
    flex: calc(1 / 5);

    ${({theme}) => theme.breakpoints.down('mobile')} {
        width: 100%;
        margin-bottom: 10px;
    }
`;

const InputContainer = styled.div<{ $nChars: number }>`
    display: inline-block;
    min-width: 30px;
    max-width: 100%;
    width: 80%;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        width: ${props => 30 + props.$nChars * 10}px;
    }
`

const AddressAndArea = (props: Props) => {
    const {address, areaString, readonly, onChange} = props;

    const area = (readonly?: boolean) => <AreaContainer>
        <Typography variant="h5" style={{fontWeight: 500}}>Boligareal</Typography>
        {areaString &&
            (readonly
                    ? <div style={{display: "flex", alignItems: "center", marginTop: 5}}>
                        <Typography>{areaString}<span style={{marginLeft: 7}}><Squared
                            symbol={"m"}/></span></Typography>
                    </div>
                    : <div style={{display: "flex", alignItems: "center"}}>
                        <InputContainer $nChars={areaString.length}>
                            <TextField id="area"
                                       value={areaString}
                                       errorTooltip={areaString == '' || parseInt(areaString) < 1 ? 'Boligarealet må ikke være ' + 0 : ''}
                                       onChange={onChange}
                                       tooltipStyle={{minWidth: 200}}/>
                        </InputContainer>
                        <Typography style={{marginLeft: 7}}><Squared symbol={"m"}/></Typography>
                    </div>
            )
        }
    </AreaContainer>

    return <AddressInfoContainer>
        <AddressContainer>
            <Typography variant="h5" style={{fontWeight: 500}}>Adresse</Typography>
            <div>
                <Typography>{address}</Typography>
            </div>
        </AddressContainer>
        {area(readonly)}
    </AddressInfoContainer>;
};

export default AddressAndArea;