import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';
import {SelectableProvider} from '@crm/contexts/selectable-context';
import {EventKey} from '@crm/contexts/selectable-context/selectable-context';

interface Props {
    children?: React.ReactNode | React.ReactNode[];
    eventKey: EventKey;
    className?: string;
    style?: HTMLAttributes<HTMLDivElement>['style'];
    'data-cy'?: string;

    onClick?: (eventKey: EventKey) => void;
}

const SelectableContainer = styled.div`
  cursor: pointer;
`;

const Selectable: React.FC<Props> = (props: Props) => {
    const {children, eventKey, onClick, className, style} = props;

    const handleOnClick = React.useCallback(() => onClick?.(eventKey), [eventKey, onClick]);

    return <SelectableProvider eventKey={eventKey}>
        <SelectableContainer style={style} className={className} onClick={handleOnClick} data-cy={props['data-cy']}>
            {children}
        </SelectableContainer>
    </SelectableProvider>;
};

export default Selectable;
