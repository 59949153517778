import React from 'react';
import {EventKey} from '../selectable-context/selectable-context';

export interface SelectableGroupContextProps<T extends EventKey> {
    children: React.ReactNode | React.ReactNode[];
    multiSelectable: boolean;
    selected?: T | T[];
}

export interface SelectableGroupContextValues<T extends EventKey> {
    multiSelectable: boolean;
    selected: T | T[];

    // activeEventKey?: T | T[];
    // handleRemoveEventKey: (key: T) => void;
    // handleAddEventKey: (key: T) => void;
}

export const SelectableGroupContext = React.createContext(
    {} as SelectableGroupContextValues<EventKey>
);

export const SelectableGroupConsumer = SelectableGroupContext.Consumer;

export const SelectableGroupProvider = <T extends EventKey>(
    props: SelectableGroupContextProps<T>
): React.JSX.Element => {
    const {children, multiSelectable, selected} = props;

    // const handleAddEventKey = React.useCallback(
    //   (key: T) => {
    //     if (multiSelectable) {
    //       setActiveEventKey((prevState) => [...((prevState || []) as T[]), key]);
    //     } else {
    //       setActiveEventKey(key);
    //     }
    //   },
    //   [multiSelectable]
    // );

    // const handleRemoveEventKey = React.useCallback(
    //   (key: T) => {
    //     if (multiSelectable) {
    //       const arrCopy = [...(activeEventKey as T[])];
    //       const index = arrCopy.indexOf(key);
    //       if (index > -1) {
    //         arrCopy.splice(index, 1);
    //         setActiveEventKey(arrCopy);
    //       }
    //     } else {
    //       setActiveEventKey(undefined);
    //     }
    //   },
    //   [multiSelectable, activeEventKey]
    // );

    const contextValue = React.useMemo(
        () => ({
            multiSelectable: multiSelectable,
            selected: selected
        }),
        [multiSelectable, selected]
    ) as SelectableGroupContextValues<EventKey>;

    return <SelectableGroupContext.Provider value={contextValue}>
        {children}
    </SelectableGroupContext.Provider>;
};

export default SelectableGroupContext;
