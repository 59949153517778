import React from 'react';
import {Transition} from 'react-transition-group';

// TODO: Change to CSSTransition
export const reflow = (node?: Element) => node && node.scrollTop;

const DEFAULT_TIMEOUT = 300;

export interface Props {
    children: React.ReactElement;
    in: boolean;
    appear?: boolean;
    timeout?: number;
    delay?: number;
    unmountOnExit?: boolean;
    opacity?: number;
    onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
    onEntered?: (node: HTMLElement, isAppearing: boolean) => void;
    onEntering?: (node: HTMLElement, isAppearing: boolean) => void;
    onExit?: (node: HTMLElement) => void;
    onExited?: (node: HTMLElement) => void;
    onExiting?: (node: HTMLElement) => void;
}

export const Fade: React.FC<Props> = (props: Props) => {
    const {
        children,
        appear = true,
        timeout = DEFAULT_TIMEOUT,
        in: inProp,
        unmountOnExit,
        onEnter,
        onEntered,
        onEntering,
        onExit,
        onExited,
        onExiting,
        ...other
    } = props;

    const nodeRef = React.useRef<HTMLElement>(null);
    const handleOnEnter = (isAppearing: boolean) => {
        // Force the animation to start from the beginning or repaint. This fixes beginning animation for onMountOnExit
        // https://github.com/reactjs/react-transition-group/issues/159#issuecomment-322735285
        nodeRef.current && reflow(nodeRef.current);

        if (onEnter && nodeRef.current) {
            onEnter(nodeRef.current, isAppearing);
        }
    };

    const handleOnEntered = React.useCallback(
        (isAppearing: boolean) => {
            if (onEntered && nodeRef.current) {
                onEntered(nodeRef.current, isAppearing);
            }
        },
        [onEntered]
    );

    const handleOnEntering = React.useCallback(
        (isAppearing: boolean) => {
            if (onEntering && nodeRef.current) {
                onEntering(nodeRef.current, isAppearing);
            }
        },
        [onEntering]
    );

    const handleOnExit = React.useCallback(() => {
        if (onExit && nodeRef.current) {
            onExit(nodeRef.current);
        }
    }, [onExit]);

    const handleOnExited = React.useCallback(() => {
        if (onExited && nodeRef.current) {
            onExited(nodeRef.current);
        }
    }, [onExited]);

    const handleOnExiting = React.useCallback(() => {
        if (onExiting && nodeRef.current) {
            onExiting(nodeRef.current);
        }
    }, [onExiting]);

    return <Transition
        timeout={timeout}
        in={inProp}
        appear={appear}
        unmountOnExit={unmountOnExit}
        onEnter={handleOnEnter}
        onEntered={handleOnEntered}
        onEntering={handleOnEntering}
        onExit={handleOnExit}
        onExited={handleOnExited}
        onExiting={handleOnExiting}
        nodeRef={nodeRef}
        {...other}
    >
        {children}
    </Transition>;
};

export default React.memo(Fade);
